import React from "react";
import Layout from "../components/layout";
import { P, TextBlockHeading, PageHeading } from "../components/typography";
import Spacer from "../components/spacer";
import SEO from "../components/seo";
import GatsbyLink from "gatsby-link";
import Cookies from "js-cookie";

const FAQ = () => {
  const textData = [
    {
      q: "How quickly can I continue my typical day after treatment?",
      a: (
        <P>
          Immediately after treatment. There is no recuperation time required.
        </P>
      )
    },
    {
      q: "How will you ensure my privacy?",
      a: (
        <P>
          Our staff is sensitive to the nature of the issue. Our scheduling is
          geared to get you in and out with a focus on your privacy and
          confidentiality. Additionally, you can view our privacy policy{" "}
          <GatsbyLink
            to="/privacy-disclosure/"
            className="text-blue-500 hover:text-blue-400"
          >
            here
          </GatsbyLink>
          .
        </P>
      )
    },
    {
      q: "Will insurance cover this?",
      a: (
        <P>
          Yes. In many cases insurance companies reimburse our patients and we
          make it as easy and seamless as possible by providing all of the
          necessary information and insurance codes. We also accept FSA and HSA,
          and we offer financing options as low as 1%.
        </P>
      )
    },
    {
      q: "How much will the insurance company pay?",
      a: (
        <>
          <P>
            Our patients get all of the necessary information based on their
            specific needs for insurance at the time of the initial
            consultation.
          </P>
        </>
      )
    }
  ];

  console.log(Cookies.get("_fbp"));
  return (
    <Layout>
      <SEO title="ED Treatment Offering FAQ" />
      <div className="flex justify-center">
        <div className="text-center px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
          <PageHeading>Q & A</PageHeading>
          <Spacer />
          {textData.map(text => (
            <div className="mb-8">
              <TextBlockHeading mb>{text.q}</TextBlockHeading>
              {text.a}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
